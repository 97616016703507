$content-width: calc(100%);
$margin: 20px;
$spacing: 20px;
$bdrs: 6px;
$circle-size: 100px;
$icon-size: 32px;
$bdrs-icon: 100%;

$color1: #0029e4;
$color2: #0029e4;

$color3: #0029e4;
$color4: #0029e4;

$color5: #0029e4;
$color6: #0029e4;
* {
  box-sizing: border-box;
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: $margin auto;
  position: relative;

  &__event {
    margin-bottom: $spacing;
    position: relative;
    display: flex;
    margin: $spacing 0;
    border-radius: $bdrs;
    align-self: center;
    width: 75%;

    &__title {
      font-size: 20px;
      font-weight: bolder;
      line-height: 1.5;
      text-transform: uppercase;
      font-weight: 600;
      color: $color1;
      letter-spacing: 1.5px;
    }
    &__content {
      padding: $spacing;
      font-size: 17px;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      background: #fff;
      width: $content-width;
      border-radius: 0 $bdrs $bdrs 0;
    }
    &__date {
      color: $color2;
      color: $color3;
      background: $color4;
      min-width: 100px;
      font-size: 2.25rem;
      font-weight: bolder;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 $spacing;
      border-radius: $bdrs 0 0 $bdrs;
    }
    &__icon {
      border: 4px solid #bedaff;
      display: flex;
      align-items: center;
      z-index: 8;
      justify-content: center;
      color: $color1;
      padding: $spacing;
      align-self: center;
      margin: 0 $spacing;
      background: white;
      border-radius: $bdrs-icon;
      width: $circle-size;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      padding: 40px;
      height: $circle-size;
      position: relative;

      &:before {
        content: "";
        width: 4px;
        height: 156%;
        background: #0029e4;
        position: absolute;
        top: 0%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
        animation: fillTop 1s forwards 2s ease-in-out;
      }
      &:after {
        content: "";
        width: 100%;
        height: 4px #0029e4;
        position: absolute;
        left: 0%;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        animation: fillLeftOdd 1s forwards 2s ease-in-out;
      }
    }
    &__description {
      flex-basis: 60%;
    }

    &--type2 {
      &:after {
        background: #0029e4;
      }
      .timeline__event__date {
        background: $color4;
        min-width: 100px;
        font-size: 2.25rem;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: #0029e4;
          }
        }
      }

      .timeline__event__icon {
        border: 4px solid #bedaff;
        background: white;
        color: $color4;
        &:before,
        &:after {
          background: #0029e4;
        }
      }
      .timeline__event__title {
        color: $color4;
      }
      .timeline__event__title {
      }
    }

    &--type3 {
      &:after {
        background: #0029e4;
        width: 4px;
        height: 4px;
      }
      .timeline__event__date {
        background: $color4;
        min-width: 100px;
        font-size: 2.25rem;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: #0029e4;
          }
        }
      }

      .timeline__event__icon {
        border: 4px solid #bedaff;
        background: white;
        color: $color5;
        &:before,
        &:after {
          background: $color6;
        }
      }
      .timeline__event__title {
        color: $color5;
      }
      .timeline__event__title {
      }
    }
    &:last-child {
      .timeline__event__icon {
        &:before {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
    align-self: center;
    &__content {
      width: 100%;
      text-align: justify;
    }

    &__icon {
      border-radius: $bdrs $bdrs 0 0;
      width: 100%;
      margin: 0;
      box-shadow: none;

      &:before,
      &:after {
        display: none;
      }
    }
    &__date {
      border-radius: 0;
      padding: $spacing;
    }

    &:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;

      .timeline__event__date {
        border-radius: 0;
        padding: $spacing;
      }

      .timeline__event__icon {
        border-radius: $bdrs $bdrs 0 0;
        margin: 0;
      }
    }
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
