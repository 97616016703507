@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

body {
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 38px;
  font-weight: 500 !important;
}

h2 {
  font-size: 25px;
  font-weight: 900;
}

h3 {
  font-size: 20px;
  font-weight: bolder;
}

p {
  font-size: 17px;
  line-height: 30px;
  color: #2c3539;
}
