h1 {
  font-size: 40px;
  font-weight: 700;
}

h2 {
  font-size: 2.25rem;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

p {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 30px;
  color: #2c3539;
}

.navbar {
  display: flex;
  gap: 40rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  background-color: white;
}

a {
  text-decoration: none;
  color: #868fa1;
}
.navbar-logo {
  max-width: 200px;
}

.upload-button:hover {
  border: 1px solid #0029e4;
  background: white;
  color: #0029e4;
}

.management-journey-section,
.how-to-use-section,
.insights-section {
  width: 75%; /* Sets width to 75% for these sections */
  margin: auto; /* Centers the content */
}
.profile-evaluation-section {
  padding: 4rem 1rem;
  display: flex;
  gap: 5vw;
  justify-content: center;
  align-items: center;
  width: 85%; /* Sets width to 60% for these sections */
  margin: auto; /* Centers the content */
  text-align: left;
}



.profile-eval-parent,
.how-it-works-parent {
  background-color: #f1f7ff;
  background-image: none;
  background-size: cover; /* Cover ensures that the background covers the entire section */
  background-position: center; /* Center the image in the section */
  width: 100%; /* Full width */
  height: 100%; /* Adju */
}
.how-it-works-parent {
  padding: 4rem 0px;
}
.text-container {
  max-width: 40vw;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-container h1 {
  margin-top: 0;
  margin-bottom: 20px; /* 20px padding below the heading */
}

.text-container p {
  margin-top: 0;
  margin-bottom: 20px; /* 20px padding below the paragraph */
}

.upload-button {
  padding: 15px 60px;
  cursor: pointer;
  background-color: #0029e4; /* Adjust the color as needed */
  color: white;
  border: none;
  border-radius: 100px;
  font-size: 18px;
  max-width: 350px;
}

.upload-button-white {
  padding: 15px 60px;
  cursor: pointer;
  background-color: white; /* Adjust the color as needed */
  color: #0029e4;

  border: 1px solid #0029e4;
  border-radius: 100px;
  font-size: 18px;
  max-width: 450px;
  margin: 35px 10px 30px 0px;
}

.upload-button-white:hover {
  border: 1px solid white;
  background: #0029e4;
  color: white;
}

.image-container {
  /* flex: 0.3; */
  padding: 20px;
  min-height: 300px; /* Adjust based on your text container's approximate height */
}

.image-container img {
  width: 25vw;
  height: 100%;
  max-height: 100%; /* Keep image height within the container */
  border-radius: 20px;
  margin-bottom: -5.5rem;
}

.management-journey-section {
  background-color: white;
  background-size: cover; /* Cover ensures that the background covers the entire section */
  background-position: center; /* Center the image in the section */
  height: 100%; /* Adju */
  padding: 4rem 0px;
  text-align: center;
  width: 100%;
}
.management-card-main {
  display: flex;
  gap: 10px;
  text-align: justify;
  flex-wrap: wrap; /* Allow cards to wrap to the next row */
  align-items: start;
  justify-content: center;
}

.management-card img {
  width: 25%;
  margin-bottom: 10px;
}

.management-card h3 {
  margin-bottom: 10px;
  text-align: left;
}
.management-card {
  background-color: white;
  width: 100%; /* By default, each card takes the full width in mobile view */
  margin-bottom: 20px; /* Add some spacing between cards */
  min-height: 320px;
  padding: 2.5rem;
  border-radius: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (min-width: 768px) {
    width: calc(
      50% - 10px
    ); /* Show 2 cards in a row on tablets with some spacing */
    margin-right: 20px; /* Add spacing between cards */
    margin-bottom: 10px;
    padding: 2.5rem;
  }

  @media (min-width: 1024px) {
    width: calc(
      30.33%
    ); /* Show 3 cards in a row on desktop with some spacing */
    margin-right: 20px; /* Add spacing between cards */
    margin-bottom: 10px;
  }
}

.management-card-main {
  width: 90%;
  margin: auto; /* Centers the section */
}

.how-to-use-section {
  width: 60%;
  margin: auto;
  text-align: center;
  padding: 35px 60px;
}

.how-to-use-section h2 {
  margin: 20px 0px 40px 0px;
}
.analysis-section-parent {
  padding: 4rem 0px;
  background-color: white;
  background-size: cover; /* Cover ensures that the background covers the entire section */
  background-position: center; /* Center the image in the section */
  width: 100%; /* Full width */
  height: 100%; /* Adju */
}

.analysis-section {
  text-align: center; /* Center the content */
  width: 75%;
  margin: 0px auto; /* Centers the section */
}

.section-title {
  padding: 0px 0px 20px 0px;
}

.drag-drop-box img {
  padding-top: 30px;
}
.upload-icon {
  margin-bottom: 10px; /* Adjust as needed /
width: 50px; / Adjust as needed */
  height: auto;
}

.cards-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.card {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
}

.card h3,
.card p {
  text-align: left;
  line-height: 20px;
}

.card h3 {
  margin-bottom: 15px;
}

.section-title {
  text-align: center;
  width: 75%;
  margin: 20px auto;
}

.upload-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 75%;
  margin: auto;
}

.drag-drop-box {
  min-height: 300px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px;
  width: 70%; /* Adjust as needed */
  text-align: center;
  cursor: pointer;
  height: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
/* Container for the progress bar adds a border, shadow for depth, and padding */
.progress-bar-container {
  width: 100%;
  max-width: 600px; /* Adjust based on your layout */
  margin: 20px auto;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* The background of the progress area */
.progress {
  background-color: #e9ecef;
  border-radius: 5px;
  height: 20px;
  overflow: hidden;
}

/* The filled portion of the progress bar */
.progress-bar {
  width: 0%; /* Initial width */
  height: 100%;
  background-color: #007bff;
  border-radius: 5px;
  transition: width 0.6s ease;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

/* Style for the motivational quote */
.motivational-quote {
  text-align: left; /* Ensures alignment to the left */
  margin-top: 10px; /* Spacing between the progress bar and the quote */
  color: #6c757d; /* A muted color for the text */
  font-style: italic; /* Optional: Make it italic for emphasis */
}

/* Modernize the animation */
@keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

/* Apply the animation to the progress bar */
.progress-bar {
  animation: loading 2s linear infinite;
}

.ai-response-box {
  width: 75%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid black;
  background-color: #f0f0f0; /* Light gray background */
  color: #333; /* Dark text for contrast */
  border-radius: 5px; /* Optional: if you want rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: for a subtle shadow */
}
.form-main {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 20px;
  width: 60%; /* Adjust the width as needed */
  align-items: center;
}

.form-input {
  width: calc(100% - 20px); /* Adjust the width to consider padding */
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.phone-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.phone-input {
  width: 100%;
}

.otp-input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-right: 10px;
}

.otp-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
  min-width: 120px; /* Set a minimum width to prevent text wrapping */
  white-space: nowrap; /* This prevents the text from wrapping */
  overflow: hidden; /* Ensures that text doesn't overflow */
  text-overflow: ellipsis;
}
.otp-button :hover {
  background-color: white;
  color: #4caf50;
  border: 1px solid #4caf50;
}

.status-message {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.status-message.success {
  background-color: green;
}

.status-message.error {
  background-color: red;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.download-button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
}
.blue-color-text {
  color: #0029e4;
}

.usage-message {
  text-align: center;
  color: #ff5e6c; /* Example color, change as needed */
  margin-top: 20px;
}

.insights-section-main {
  background: #f1f7ff;
  padding: 4rem 10px;
  width: 100%;
  border-radius: 50px 50px 0 0;
}

.insights-section {
  text-align: center;
  color: black;
}

.insights-section p {
  text-align: center;
  align-items: center;
  color: black;

  margin: auto;
}

.insights-section h2 {
  margin: 0px 0px 20px 0px;
}

.otp-button:hover {
  background-color: white;
  color: #4caf50;
  border: 1px solid #4caf50;
}

.footer {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.footer-list-item {
  cursor: pointer;
  color: #868fa1;
  display: grid;
  gap: 2rem;
  grid-template-columns: auto auto auto auto;
}

/* Responsive adjustments */

@media (max-width: 1300px) {
  .drag-drop-box {
    width: 75%;
  }
}

@media (max-width: 1200px) {
  .image-container {
    min-height: 200px; /* Adjust for smaller screens */
  }
}

@media (max-width: 1025px) {
  .image-container {
    display: none; /* Hide image on very small screens */
  }
  .text-container {
    max-width: 98vw;
  }
  .card img,
  .navbar button {
    display: none;
  }
}

@media (max-width: 850px) {
  .how-to-use-section,
  .profile-evaluation-section {
    width: 70%;
  }

  .management-journey-section .management-text {
    width: 75%;
  }

  .how-to-use-section {
    width: 72%;
  }

  .analysis-section,
  .insights-section p {
    width: 100%;
  }

  .form-main {
    width: 80%;
  }

  .ai-response-box {
    width: 90%;
  }
}

@media (max-width: 780px) {
  .otp-button {
    width: auto; /* Adjust the width as needed */
    max-width: 150px; /* Prevent the button from taking full width */
    margin-top: 8px; /* Add margin-top to the Send OTP button */
    margin-bottom: 8px; /* Ensure space below the button */
    margin-right: auto; /* Center the button */
    margin-left: auto; /* Center the button */
  }
  /* .react-tel-input .form-control {
    width: 94% !important;
  } */
  .phone-container {
    margin-right: 0;
    margin-bottom: 8px;
  }
  .form-phone-row .otp-button,
  .form-verify-row .otp-button {
    margin-top: 8px; /* Add space above the Send OTP button */
  }
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .phone-input,
  .otp-input {
    width: 94%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .profile-evaluation-section {
    align-items: center;
    display: flex;
    gap: 5vw;
    justify-content: center;
    margin: auto;
    padding: 4rem 1rem;
    text-align: left;
    width: 85%;
}

.management-card-main {
  text-align: left;
}

.timeline__event__content {
  text-align: left;
}
 
.footer {
  align-items: normal;
}
  .download-button-container {
    flex-direction: column;
  }
  .cards-container {
    flex-direction: column;
  }

  .management-card img {
    width: 20%;
  }
  .footer {
    flex-direction: column;
  }
  .footer-list-item {
    padding: 1.5rem;
  }
}

@media (max-width: 425px) {
  .upload-section {
    width: 95%;
  }
  .management-card {
    min-width: 250px;
    min-height: 250px;
    padding: 1.5rem;
  }
  .upload-button,
  .upload-button-white {
    padding: 10px 20px;
  }
  .footer-list-item {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 381px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .upload-button,
  p {
    font-size: 1.2rempx;
  }
}
